.footer {
  background-color: #7ed3f0;
  padding:1rem ;
  
}

.title {
  color: #1a1410;
}

.description {
  color: #1a1410;
}

.footerlink,
.footerlink:hover {
  color: #1a1410;
}
.intro {
  color: #7ed3f0;
}