html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 80px !important; /* Height of the footer */
  padding: 0;
  background-color: #7ed3f0;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80px; /* Set the fixed height of the footer here */
}

/* Override Bootstrap Styling */

.btn-primary {
  background-color: #025fce !important;
}

button:focus,
button:active {
  outline: none !important;
  box-shadow: none !important;
}
div{
  background-color:#7ed3f0;
  /* background: radial-gradient(circle, rgba(37,170,226,0.6418942577030813) 0%, rgba(37,190,226,0.5130427170868348) 100%); */
 }